import { Label } from '@rsa-digital/evo-shared-components/components/Form/Field/FieldInfo';
import { StyledLabel } from '@rsa-digital/evo-shared-components/components/Form/Field/FieldInfo/Label/styles';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import CoverStartDateQuestion from 'forms/AboutYourPolicyForm/CoverStartDateQuestion';

export const StyledCoverStartDateQuestion = styled(CoverStartDateQuestion)`
  ${Label} {
    ${StyledLabel} {
      ${fonts.headingSmall}
    }

    & > ${StyledLabel} {
      margin: ${spacing(6)} 0 ${spacing(3)};

      ${mediaQuery.tabletPortrait`
        margin: ${spacing(2)} 0 ${spacing(3)}
      `}

      ${mediaQuery.tabletLandscape`
        margin: ${spacing(8)} 0 ${spacing(3)};
      `}
    }
  }
`;
