import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import { graphql, navigate } from 'gatsby';
import React from 'react';
import LoadQuoteWrapper from 'components/ApiRequestWrapper/LoadQuoteWrapper';
import Layout from 'components/Layout';
import ConfirmCoverStartDateForm from 'forms/ConfirmCoverStartDateForm';
import { PageTitle } from 'helpers/eventTracking';
import { usePageTracking } from 'helpers/pageTracking';
import {
  useResetTescoPageLoadTrackingOnRefresh,
  useTescoPageLoadTracking,
  useTescoPageViewTracking,
} from 'helpers/pageTrackingForTesco';
import { quotePlaceholders } from 'helpers/placeholders/quotePlaceholders';
import { replacePlaceholdersPlainText } from 'helpers/placeholders/replaceCsPlaceholders';
import { quoteAndBuyRoutes } from 'helpers/routingHelper';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import { CsHero } from 'types/contentStack';

type ConfirmCoverStartDateProps = {
  data: {
    csPetConfirmCoverStartDate: {
      meta_title: string;
      hero: CsHero;
      question_text: string;
      explanatory_text: string;
    };
  };
};

export const query = graphql`
  query {
    csPetConfirmCoverStartDate {
      meta_title
      hero {
        heading
      }
      explanatory_text
      question_text
    }
  }
`;

const ConfirmCoverStartDate: React.FC<ConfirmCoverStartDateProps> = ({
  data: {
    csPetConfirmCoverStartDate: { meta_title, hero, question_text, explanatory_text },
  },
}) => {
  const moveNext = (): void => {
    navigate(quoteAndBuyRoutes.loadingQuote);
  };

  const quote = useCurrentQuote();
  usePageTracking(meta_title);
  useTescoPageLoadTracking();
  useResetTescoPageLoadTrackingOnRefresh();
  useTescoPageViewTracking();

  const heading = replacePlaceholdersPlainText(
    { customerFirstName: quotePlaceholders.customerFirstName },
    quote,
    true
  )(hero.heading);

  return (
    <LoadQuoteWrapper>
      <Layout
        metaTitle={meta_title}
        pageTitle={PageTitle.ConfirmCoverStartDate}
        heading={heading}>
        <Grid>
          <GridItem desktop={6} tabletLandscape={6} tabletPortrait={6}>
            <ConfirmCoverStartDateForm
              moveNext={moveNext}
              questionText={question_text}
              explanatoryText={explanatory_text}
            />
          </GridItem>
        </Grid>
      </Layout>
    </LoadQuoteWrapper>
  );
};

export default ConfirmCoverStartDate;
